import SubCard from '../SubCard';
import { Box, Card, CardContent, Divider, Typography, Badge } from '@mui/material';
import WrapperBox from './dashboard-card-Style';
import { useNavigate } from 'react-router-dom';

const DashboardCard = ({ data }) => {
    const { Icon, content, title, navigateTo } = data;
    const navigate = useNavigate();
    return (
        <WrapperBox>
            <SubCard
                onClick={() => navigate(navigateTo)}
                title={title}
                sx={{ cursor: 'pointer' }}
                secondary={
                    <Box className="icon-box">
                        <Icon className="icon" />
                    </Box>
                }
            >
                <Card className="cardStyle">
                    <CardContent>
                        {content.map((item, index) => (
                            <Box key={index}>
                                <Box className="content">
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography mr={3} sx={{ flex: 1 }} variant="subtitle2">
                                            {item.text}
                                        </Typography>
                                        <Typography variant="subtitle2">: {item.value}</Typography>
                                    </Box>

                                    {/* <Badge badgeContent={item.value} /> */}
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                    </CardContent>
                </Card>
            </SubCard>
        </WrapperBox>
    );
};
export default DashboardCard;
