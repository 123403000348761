import { Box, Grid } from '@mui/material';
import * as React from 'react';

import { gridSpacing } from 'store/constant';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

import DashboardCard from 'ui-component/cards/dasboard-card/dashboard-card';

import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'store/slices/user';
import { labManagementDashBoard } from 'utils/apis/lab-test';
import { sendDateFormate } from 'assets/function utils/functionUtils';

const ManagePickups = () => {
    const { userRoles } = useSelector(loggedInUserSelector);
    const [dashboard, setDashBoards] = React.useState([]);
    const [dashboardArray, setDashboardArray] = React.useState([
        {
            title: 'Track Pickup/ Schedule Track',
            Icon: TransferWithinAStationIcon,
            content: [
                { text: 'Samples Tracking Management', value: '' },
                { text: 'Create a New Tracking and Receive Gaiapods ', value: '' }
            ],
            navigateTo: '/manage-pickups/tracking',
            screenKeyName: 'TRACK_PICKUPS'
        },
        {
            title: 'Lost Items',
            Icon: CallMissedIcon,
            content: [
                { text: 'Lost and Found Items', value: '' },
                { text: '', value: '' }
            ],
            navigateTo: '/manage-pickups/lost-items-list',
            screenKeyName: 'LOST_ITEMS'
        },
        {
            title: 'Gaiapod Management',
            Icon: PhonelinkRingIcon,
            content: [
                { text: 'Manage Gaiapods', value: '' },
                { text: 'Add New Gaiapod or Assign to user ', value: '' }
            ],
            navigateTo: '/manage-pickups/gaiapod-device-management',
            screenKeyName: 'GAIAPOD_MANAGEMENT'
        }
    ]);
    const fetchDashboardData = async () => {
        await labManagementDashBoard({ current_date: sendDateFormate(new Date()) });
        setDashboardArray([
            {
                title: 'Track Pickup/ Schedule Track',
                Icon: TransferWithinAStationIcon,
                content: [
                    { text: 'Samples Tracking Management', value: '' },
                    { text: 'Create a New Tracking and Receive Gaiapods ', value: '' }
                ],
                navigateTo: '/manage-pickups/tracking',
                screenKeyName: 'TRACK_PICKUPS'
            },
            {
                title: 'Lost Items',
                Icon: CallMissedIcon,
                content: [
                    { text: 'Lost and Found Items', value: '' },
                    { text: '', value: '' }
                ],
                navigateTo: '/manage-pickups/lost-items-list',
                screenKeyName: 'LOST_ITEMS'
            },
            {
                title: 'Gaiapod Management',
                Icon: PhonelinkRingIcon,
                content: [
                    { text: 'Manage Gaiapods', value: '' },
                    { text: 'Add New Gaiapod or Assign to user ', value: '' }
                ],
                navigateTo: '/manage-pickups/gaiapod-device-management',
                screenKeyName: 'GAIAPOD_MANAGEMENT'
            }
        ]);
    };
    React.useEffect(() => {
        fetchDashboardData();
    }, []);
    React.useEffect(() => {
        const screensObj = {};
        userRoles.forEach((userScrnData) => {
            if (userScrnData.module_screen_key_name === 'TRACKING_DASH') {
                screensObj[userScrnData.sub_screen_key_name] = 1;
            }
        });
        setDashBoards(dashboardArray.filter((data) => Object.hasOwn(screensObj, data.screenKeyName)));
    }, [userRoles, dashboardArray]);

    return (
        <Box>
            <Grid container spacing={gridSpacing}>
                {dashboard.map((item) => (
                    <Grid item xs={12} lg={4}>
                        <DashboardCard data={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ManagePickups;
