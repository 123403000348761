import { securedAxiosAPI, basicAxios } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    addCustomer: 'client/add',
    byPagination: 'client/customerByPagination',
    allCustomer: 'client',
    base: 'client',
    dynamicFilter: '/client/dynamicFilter',
    addCustomerAddress: '/client/add-address',
    updateCustomerAddress: '/client/update-address',
    getCustomerAddress: '/client/customer-address'
};
const usnsecureRoute = {
    getcustomerById: 'customer'
};

export const getcustomerById = async (id) => {
    const res = await basicAxios.get(`${usnsecureRoute?.getcustomerById}/${id}`);
    return res.data[0];
};
export const getAllCustomers = async () => {
    const res = await securedAxiosAPI.get(`${securedRoute?.allCustomer}`);
    return res.data;
};
export const getCustomerByPagination = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().customer;
    const res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage });
    return res.data;
};
export const addNewCustomer = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addCustomer, data);
    return res.data;
};
export const getCustomerDetails = async (data) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}/${data}`);
    return res.data[0];
};
export const deleteCustomer = async (data) => {
    const res = await securedAxiosAPI.delete(`${securedRoute?.base}/${data}`);
    return res.data[0];
};
// dynamicFilter
export const dynamicFilter = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilter, data);
    return res.data;
};

// Customer Address
export const addCustomerAddress = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addCustomerAddress, data);
    return res.data;
};
// Customer Address Update
export const updateCustomerAddress = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateCustomerAddress, data);
    return res.data;
};
// Customer Address lsit
export const customerAddressList = async (customerId) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.getCustomerAddress}?customerId=${customerId}`);
    return res.data;
};
