import { styled, Box } from '@mui/material';

const WrapperBox = styled(Box)(({ theme }) => ({
    '.icon-box': {
        boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 1
    },
    '.icon': {
        fontSize: '27px'
    },
    '.cardStyle': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    },
    '.content': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));
export default WrapperBox;
