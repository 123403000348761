import { takeLatest, all, call, put } from 'redux-saga/effects';
import { getGaiapodList, addNewGaiapodToList, updateGaiapodById, getGaiapodById, getGaiapodHistoryById } from 'utils/apis/gaiapod';
import {
    completedAction,
    startGaiapodList,
    setGaiapodList,
    startAddingGaiapodList,
    startUpdatingGaiapodData,
    startGettingGaiapodHistory,
    setGaiapodHistory
} from './gaiapod.slice';
import { openSnackbar } from '../snackbar';
//   List  Response (1)
function* gaiapodListResponseHandler() {
    try {
        const response = yield call(getGaiapodList);
        yield put(setGaiapodList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    List  (1)
function* startGaiapodListWatcher() {
    yield takeLatest(startGaiapodList.type, gaiapodListResponseHandler);
}

// //   Add  Response (2)
function* gaiapodAddResponseHandler({ payload }) {
    try {
        yield call(addNewGaiapodToList, payload);
        yield put(startGaiapodList());
        // yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    Add  (2)
function* startAddNewGaiapodWatcher() {
    yield takeLatest(startAddingGaiapodList.type, gaiapodAddResponseHandler);
}

// //   Update  Response (3)
function* gaiapodUpdateResponseHandler({ payload }) {
    try {
        yield call(updateGaiapodById, payload);
        yield put(startGaiapodList());
        // yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    Update  (3)
function* startUpdateGaiapodWatcher() {
    yield takeLatest(startUpdatingGaiapodData.type, gaiapodUpdateResponseHandler);
}

// //   Get History By Id  Response (4)
function* gaiapodHistoryByIdResponseHandler({ payload }) {
    try {
        const res = yield call(getGaiapodHistoryById, payload);
        yield put(setGaiapodHistory(res));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    Get History By Id Watcher  (4)
function* startGettingGaiapodHistoryByIdWatcher() {
    yield takeLatest(startGettingGaiapodHistory.type, gaiapodHistoryByIdResponseHandler);
}

// Accumulator
export default function* gaiapodSaga() {
    yield all([
        call(startGaiapodListWatcher),
        call(startAddNewGaiapodWatcher),
        call(startUpdateGaiapodWatcher),
        call(startGettingGaiapodHistoryByIdWatcher)
    ]);
}
