import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';

const securedRoute = {
    pickups: 'pickupRequest/pickup-list-by-status/##STATUS##',
    generateNewTracking: 'pickupRequest/create-tracking-request',
    trackingList: 'pickupRequest/trackings',
    getTrackDetailsByTackId: 'pickupRequest/tracking?trackingId=##TRACKID##',
    getTrackLocationHistory: 'pickupRequest/tracking/location-history/##TRACKID##',
    updateTrackIdStatus: 'pickupRequest/tracking/##TRACKID##/status',
    lostSamples: 'testRequest/lostTestRequests',
    lostSampleDetails: 'testRequest/lostTestRequest/##TRACKID##/##PICKUPID##'
};
// Pcikup List by Courier User ID
export const getPickupListByID = async (data) => {
    const { status, courierPartner } = data;
    const res = await securedAxiosAPI.get(
        securedRoute?.pickups.replaceAll('##STATUS##', status).replaceAll('##COURIERID##', courierPartner)
    );
    return res.data;
};
// Generate new Track Id
export const generateNewTracking = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.generateNewTracking, data);
    return res.data;
};
// Tracking List
export const getTrackingList = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().trackingPickup;
    const res = await securedAxiosAPI.get(`${securedRoute?.trackingList}?page=${page + 1}&limit=${rowsPerPage}`);
    return res.data;
};
/// Tracking Info
export const getTrackingDetilsByTackingID = async (trackId) => {
    const res = await securedAxiosAPI.get(securedRoute?.getTrackDetailsByTackId.replaceAll('##TRACKID##', trackId));
    return res.data;
};
// Update
export const updateTrackIdStatus = async (result) => {
    const { trackId } = result;
    const res = await securedAxiosAPI.patch(securedRoute?.updateTrackIdStatus.replaceAll('##TRACKID##', trackId), result);
    return res.data;
};

/// Tracking Info
export const getTrackingLocationHistory = async (trackId) => {
    const res = await securedAxiosAPI.get(securedRoute?.getTrackLocationHistory.replaceAll('##TRACKID##', trackId));
    return res.data?.results;
};
// Lost Samples

export const getLostSamplesList = async () => {
    const resp = await securedAxiosAPI.get(securedRoute?.lostSamples);
    return resp.data;
};
// Lost Detials
export const getLostSamplesDetailsList = async (trackId, pickupId) => {
    const resp = await securedAxiosAPI.get(
        securedRoute?.lostSampleDetails.replaceAll('##TRACKID##', trackId).replaceAll('##PICKUPID##', pickupId)
    );
    return resp.data;
};
