// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    //
    gaiapodList: [],
    gaiapodHistory: {
        gaiapodDeviceDetails: {},
        gaiapodDeviceLogs: []
    },

    isLoading: false,
    message: ''
};

const slice = createSlice({
    name: 'gaiapod',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startGaiapodList(state, action) {
            state.isLoading = true;
        },
        setGaiapodList(state, action) {
            state.gaiapodList = action.payload;
        },
        startAddingGaiapodList(state, action) {
            state.isLoading = true;
        },
        startGettingGaiapodHistory(state, action) {
            state.isLoading = true;
        },
        setGaiapodHistory(state, action) {
            const { gaiapodDeviceDetails, gaiapodDeviceLogs } = action.payload;
            state.gaiapodHistory = {
                gaiapodDeviceDetails: gaiapodDeviceDetails[0],
                gaiapodDeviceLogs
            };
        },
        //
        startUpdatingGaiapodData(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
        }
    }
});

// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    completedAction,
    startGaiapodList,
    setGaiapodList,
    startAddingGaiapodList,
    startUpdatingGaiapodData,
    startGettingGaiapodHistory,
    setGaiapodHistory
} = slice.actions;
// Reducer
export default slice.reducer;
// selectors
const gaiapodState = (state) => state.gaiapod;
/// Data Loading Selector
export const isLoadingSelector = createSelector([gaiapodState], (gaiapodDataState) => gaiapodDataState.isLoading);
// Page Selector
export const pageSelector = createSelector([gaiapodState], (gaiapodDataState) => gaiapodDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([gaiapodState], (gaiapodDataState) => gaiapodDataState.rowsPerPage);
// lsit
export const gaiapodListSelector = createSelector([gaiapodState], (gaiapodDataState) => gaiapodDataState.gaiapodList);
// History
export const gaiapodHistorySelector = createSelector([gaiapodState], (gaiapodDataState) => gaiapodDataState.gaiapodHistory);
