// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    userPage: 0,
    userRowsPerPage: 10,
    isLoading: false,
    isLoadingForAddress: false,
    message: '',
    customer: [],
    customersUsers: [],
    userDetails: {},
    customerDetails: {},
    customerAddressList: []
};

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setUserPage(state, action) {
            state.userPage = action.payload;
        },
        setUserRowsPerPage(state, action) {
            state.userRowsPerPage = action.payload;
        },
        // Pagination
        startAddingNewCustomer(state, action) {
            state.isLoading = true;
        },

        // Dynamic Filter
        startDynamicFilter(state, action) {
            state.isLoading = true;
        },
        startAllCustomer(state, action) {
            state.isLoading = true;
        },
        setCustomers(state, action) {
            state.customer = action.payload;
        },
        // by pagination
        startUsersByCustomerId(state, action) {
            state.isLoading = true;
        },
        setCustomerUsers(state, action) {
            state.customersUsers = action.payload;
        },
        startCustomerDetailsOnID(state, action) {
            state.isLoading = true;
        },
        customerDetials(state, action) {
            state.customerDetails = action.payload;
        },
        startDeleteCustomer(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
            state.isLoadingForAddress = false;
        },
        //  customer users
        startAddingCustomerUser(state, action) {
            state.isLoading = true;
        },
        startUpdatingcUser(state, action) {
            state.isLoading = true;
        },
        startCUserData(state, action) {
            state.isLoading = true;
        },
        setcUserData(state, action) {
            state.userDetails = action.payload;
        },
        // Customer Address list
        startCustomerAddressList(state, action) {
            state.isLoadingForAddress = true;
        },
        setCustomerAddressList(state, action) {
            //
            state.customerAddressList = action.payload;
            //
        },
        // Add or Edit New Address in Customer
        startAddOrEditAddress(state, action) {
            state.isLoadingForAddress = true;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    setUserPage,
    setUserRowsPerPage,
    startDeleteCustomer,
    startUsersByCustomerId,
    setCustomerUsers,
    setCustomers,
    startAllCustomer,
    completedAction,
    startAddingNewCustomer,
    customerDetials,
    startCustomerDetailsOnID,
    startAddingCustomerUser,
    startDynamicFilter,
    setcUserData,
    startCUserData,
    startUpdatingcUser,
    // Address Management
    startCustomerAddressList,
    setCustomerAddressList,
    startAddOrEditAddress
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors

const customerStates = (state) => state.customer;
/// Data Loading Selector
export const isLoadingSelector = createSelector([customerStates], (customerDataState) => customerDataState.isLoading);
//   Loading For Address  isLoadingForAddress
export const isLoadingForAddressSelector = createSelector([customerStates], (customerDataState) => customerDataState.isLoadingForAddress);
// LoggedIn-user Selector
export const customerDetailsSelector = createSelector([customerStates], (customerDataState) => customerDataState.customerDetails);
// Customers
export const customersSelector = createSelector([customerStates], (customerDataState) => customerDataState.customer);
// Customers Users
export const customerUsersSelector = createSelector([customerStates], (customerDataState) => customerDataState.customersUsers);
// Page Selector
export const pageSelector = createSelector([customerStates], (customerDataState) => customerDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([customerStates], (customerDataState) => customerDataState.rowsPerPage);

// User Page Selector
export const userPageSelector = createSelector([customerStates], (customerDataState) => customerDataState.userPage);
// User Row Per Page Selector
export const userRowsPerPageSelector = createSelector([customerStates], (customerDataState) => customerDataState.userRowsPerPage);
// userDetails
export const userDetailsSelector = createSelector([customerStates], (customerDataState) => customerDataState.userDetails);
export const allCustomersSelector = createSelector([customerStates], (customerDataState) => customerDataState.customer);
//
export const customerAddressListSelector = createSelector([customerStates], (customerDataState) => customerDataState.customerAddressList);
//
