import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    completedAction,
    startGettingTrackList,
    setTrackingList,
    startAddingTrackingList,
    startLostSamplesList,
    setLostSamplesList,
    startLostGaiapodList,
    setLostGaiapodList
} from './pickupTracking.slice';
import { generateNewTracking, getLostSamplesList, getTrackingList } from 'utils/apis/pickup-track';
import { openSnackbar } from '../snackbar';
//   List  Response (1)
function* trackingListResponseHandler() {
    try {
        const response = yield call(getTrackingList);

        yield put(setTrackingList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    List  (1)
function* startTrackingListhWatcher() {
    yield takeLatest(startGettingTrackList.type, trackingListResponseHandler);
}

//   Add  Response (2)
function* trackingAddResponseHandler({ payload }) {
    try {
        yield call(generateNewTracking, payload);
        yield put(startGettingTrackList());
        // yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//    Add  (2)
function* startAddNewTrackinghWatcher() {
    yield takeLatest(startAddingTrackingList.type, trackingAddResponseHandler);
}
// Lost Samples List (3)
function* lostSamplesLisResponseHandler() {
    try {
        const response = yield call(getLostSamplesList);
        yield put(setLostSamplesList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Lost Samples List  (3)
function* startLostSamplesListhWatcher() {
    yield takeLatest(startLostSamplesList.type, lostSamplesLisResponseHandler);
}

// Lost Gaiapods List (4)
function* lostGaiapodListResponseHandler() {
    try {
        //  const response = yield call(getLostSamplesList);
        // yield put(setLostGaiapodList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Lost Gaiapods List  (4)
function* startLostGaiapodListWatcher() {
    yield takeLatest(startLostGaiapodList.type, lostGaiapodListResponseHandler);
}

// Accumulator
export default function* generatedTracking() {
    yield all([
        call(startTrackingListhWatcher),
        call(startAddNewTrackinghWatcher),
        call(startLostSamplesListhWatcher),
        call(startLostGaiapodListWatcher)
    ]);
}
