import { useEffect, useState, lazy } from 'react';

// routing
// import Routes from 'routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// project imports
// import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';

import Snackbar from 'ui-component/extended/Snackbar';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ================================================================================|| APP ||========================================================================== //

import Loadable from 'ui-component/Loadable';
import { Routes, Route } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { loggedInPortalSelector, loggedInUserSelector } from 'store/slices/user';
import MapRoutes from 'routes/MapRoutes';
import { startCustomerDetailsOnID } from 'store/slices/customer/customer';

const AccessDenied = Loadable(lazy(() => import('views/access-denied')));
const MinimalLayout = Loadable(lazy(() => import('layout/MinimalLayout')));
const NavMotion = Loadable(lazy(() => import('layout/NavMotion')));
// const MapRoutes = Loadable(lazy(() => import('routes/MapRoutes')));

const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const EditProfileData = Loadable(lazy(() => import('views/edit-profile')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Error404 = Loadable(lazy(() => import('views/pages/maintenance/Error404')));
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')));

const App = () => {
    // const pathName = useLocation().pathname;
    const dispatch = useDispatch();
    const loggedInUserData = useSelector(loggedInUserSelector);
    const loggedInPortal = useSelector(loggedInPortalSelector);
    const [myroleRoutes, setMyRoleRoutes] = useState({ DASHBOARD: true });
    // alert(JSON.stringify(loggedInUserData));
    useEffect(() => {
        if (loggedInUserData?.userRoles.length > 0) {
            //   alert(loggedInPortal);
            const Obj = { DASHBOARD: true };
            loggedInUserData?.userRoles.forEach((data) => {
                Obj[data?.sub_screen_key_name] = true;
                Obj[data?.module_screen_key_name] = true;
            });
            setMyRoleRoutes(Obj);
        }
        if (loggedInPortal === 'CUSTOMER') {
            if (loggedInUserData?.customerId) dispatch(startCustomerDetailsOnID(loggedInUserData?.customerId));
        }
    }, [loggedInUserData, loggedInPortal]);
    console.log(myroleRoutes, 'myroleRoutes');
    return (
        <ThemeCustomization>
            <NavigationScroll>
                <AuthProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Routes>
                            <Route
                                element={
                                    <NavMotion>
                                        <MinimalLayout />
                                    </NavMotion>
                                }
                            >
                                <Route path="/login" element={<AuthLogin />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/login/customer-connect/:customer_id" element={<AuthLogin />} />
                            </Route>
                            <Route element={<AuthGuard>{loggedInUserData?.userID ? <MainLayout /> : <Loadable />}</AuthGuard>}>
                                {MapRoutes?.map((data) => (
                                    <Route
                                        exact
                                        path={data.path}
                                        element={myroleRoutes[data.screenKeyName] ? <data.element /> : <AccessDenied />}
                                    />
                                ))}
                                <Route exact path="/profile" element={<Profile />} />
                                <Route exact path="/profile/edit" element={<EditProfileData />} />
                                <Route exact path="/denied" element={<AccessDenied />} />
                            </Route>
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                        <Snackbar />
                    </LocalizationProvider>
                </AuthProvider>
            </NavigationScroll>

            {/* <Route exact path={data.path} element={myroleRoutes[data.screenKeyName] ? <data.element /> : <AccessDenied />} /> */}
        </ThemeCustomization>
    );
};

export default App;
