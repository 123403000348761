import { store } from '../../store';
import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    base: 'lookupDetails',
    masterLookup: 'lookupDetails/lookup-types',
    allByPagination: 'lookupDetails/byPagination',
    lookupIdByPagination: 'lookupDetails/lookup_id/byPagination',
    reportingLevel: 'lookupDetails/view-reporting-levels',
    updateStatusById: 'lookupDetails/updateStatusById',
    updateLookupData: 'lookupDetails/updateLookupData',
    // PRODUCTS-MANAGEMENT
    productListByColumn: 'lookupDetails/productDetails/columnDynamicFilter',
    // Delivery Address
    congigLabDeliveryAddress: 'lab-config/address'
};

export const addLookUpDetails = async (data) => {
    const result = await securedAxiosAPI.post(`${securedRoute?.base}/add`, data);
    return result;
};

export const getLookupMasterData = async () => {
    const res = await securedAxiosAPI.get(securedRoute?.masterLookup);
    return res.data;
};

export const GetLookUpDetailsDataByLookupId = async (data) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}/lookup_id/${data}`);
    return res.data;
};
export const GetLookUpDetailsDataByLookupIdAndPagination = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lookupDetails;
    const res = await securedAxiosAPI.post(securedRoute?.lookupIdByPagination, { ...data, page, rowsPerPage });
    return res.data;
};
export const getLookUpDetailsData = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lookupDetails;
    const res = await securedAxiosAPI.post(securedRoute?.allByPagination, { page, rowsPerPage });
    return res.data;
};
export const deleteLookupDetailsData = async (id) => {
    const result = await securedAxiosAPI.delete(`${securedRoute?.base}/${id}`);

    return result;
};
// get reporting level
export const getAllReportingLevel = async () => {
    const result = await securedAxiosAPI.get(securedRoute?.reportingLevel);
    return result.data;
};
//
export const updateStatusById = async (data) => {
    const result = await securedAxiosAPI.post(securedRoute?.updateStatusById, data);

    return result;
};
//
export const updateLookupData = async (data) => {
    const result = await securedAxiosAPI.post(securedRoute?.updateLookupData, data);
    return result;
};

// Products Column List
export const productsListByColumn = async (payload) => {
    const { getState } = store;

    const { columnFilterName, paginationsPageName } = payload;
    console.log(payload, 'payload', getState().lookupDetails);
    const { paginations, [columnFilterName]: columnFilter, appliedCustomerFIlter } = getState().lookupDetails;
    const { page, rowsPerPage } = paginations[paginationsPageName];

    const { customerId } = getState().user.loggedInUser;

    const filters = Object.keys(columnFilter);
    const data = {
        pName: [],
        pCode: [],
        status: [],
        reqDate: []
    };
    filters.forEach((filterName) => {
        columnFilter[filterName].appliedList.forEach((item) => {
            if (filterName === 'tatDate' || filterName === 'reqDate') {
                data[filterName].push(item);
            } else {
                data[filterName].push(item.id);
            }
        });
    });

    const res = await securedAxiosAPI.post(`${securedRoute?.productListByColumn}`, {
        ...data,
        customerIds: appliedCustomerFIlter,
        page,
        rowsPerPage,
        customerId
    });

    return res.data;
};

// config Lab Delivery Address
// get List
export const getAllLabDeliveryAddress = async () => {
    const result = await securedAxiosAPI.get(securedRoute?.congigLabDeliveryAddress);
    return result.data;
};
// Add New Lab Devivery Location
export const addLabDeliveryAddress = async (data) => {
    const result = await securedAxiosAPI.post(securedRoute?.congigLabDeliveryAddress, data);
    return result.data;
};

// update Existing Lab Devivery Location
export const updateLabDeliveryAddress = async (data) => {
    const result = await securedAxiosAPI.put(securedRoute?.congigLabDeliveryAddress, data);
    return result.data;
};
