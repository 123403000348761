import { createSlice, createSelector } from '@reduxjs/toolkit';

const dayjs = require('dayjs');

const slice = createSlice({
    name: 'lookUpDetailsDataSlice',
    initialState: {
        page: 0,
        rowsPerPage: 10,
        productsList: [],
        isLoading: false,
        isLoadingForAddress: false,
        lookupDetailsData: [],
        lookupMasterData: [],
        lookupDetailForId1: [],
        lookupDetailForId2: [],
        lookupDetailForId3: [],
        lookupDetailForId4: [],
        lookupDetailForId5: [],
        //
        productsDetailsList: [],
        columnFilter: {
            pName: {
                appliedList: [],
                checkedList: []
            },
            pCode: {
                appliedList: [],
                checkedList: []
            },
            status: {
                appliedList: [],
                checkedList: []
            },
            reqDate: {
                appliedList: [],
                checkedList: [
                    {
                        startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                        endDate: dayjs(new Date()).format('MM-DD-YYYY')
                    }
                ]
            }
        },
        // separate customer filter for Product Management
        appliedCustomerFIlter: [],
        paginations: {
            productsManagementPage: {
                page: 0,
                rowsPerPage: 10
            }
        },
        pageLeft: {
            productsManagementPage: false
        },
        deliveryAddressList: []
    },
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startLookUpDetails(state, action) {
            state.isLoading = true;
        },
        // Fixed Column Pagination
        // All Pagination
        // Set Page Left with applied filter status
        setPageLeft(state, action) {
            const { filterPageName, value } = action.payload;
            const pageLeft = {
                ...state.pageLeft,
                [filterPageName]: value
            };
            state.pageLeft = pageLeft;
        },
        setPaginationByPageNames(state, action) {
            const { filterPageName, page, rowsPerPage } = action.payload;
            const paginations = {
                ...state.paginations
            };
            paginations[filterPageName] = {
                page,
                rowsPerPage
            };
            state.paginations = paginations;
        },
        // All Pagination
        // Column filter
        setColumnCheckedList(state, action) {
            const { columnName, data, status, listType, newValue, columnFilterName = 'columnFilter' } = action.payload;
            if (listType === 'DATE' && data) {
                //
                // data =>  startDate or endDate
                //
                let tempColumnFilterOne = { ...state[columnFilterName] };

                tempColumnFilterOne[columnName].checkedList[0][data] = newValue;
                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else if (status) {
                // status refers to checkbox status
                // new item is checked
                // if a new item is checked it will always enter the checked list
                // data ===> id / name based on filter type
                let tempColumnFilterOne = { ...state[columnFilterName] };
                tempColumnFilterOne[columnName].checkedList.push(data);

                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // if the item is getting unchecked that may be in a part of appliedList or checkedList ( we need to hydrate the checklist for consistency )
                // but need to keep in mind untill apply button is pressed don't remove or add to the appliedList
                // That means if item is in the applied list it must be on the checklist
                // on press of apply Button Checked list's data is copied to the Applied list
                let tempColumnFilterOne = { ...state[columnFilterName] };
                let i = 0;
                // eslint-disable-next-line no-plusplus
                for (i; i < tempColumnFilterOne[columnName].checkedList.length; i++) {
                    if (tempColumnFilterOne[columnName].checkedList[i].id === data.id) {
                        break;
                    }
                }
                tempColumnFilterOne[columnName].checkedList.splice(i, 1);

                state[columnFilterName] = { ...tempColumnFilterOne };

                tempColumnFilterOne = {};
            }
        },
        clearUnsavedCheckList(state, action) {
            const { columnName, listType, columnFilterName = 'columnFilter' } = action.payload;
            if (columnName && listType !== 'DATE') {
                let tempColumnFilterOne = { ...state[columnFilterName] };
                tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // eslint-disable-next-line no-lonely-if
                if (columnName) {
                    // DATE list-type
                    let tempColumnFilterOne = { ...state[columnFilterName] };
                    if (tempColumnFilterOne[columnName].appliedList.length > 0) {
                        // make checklist same as applied list on close of menu
                        tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                        state[columnFilterName] = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    } else {
                        tempColumnFilterOne[columnName].checkedList = [
                            {
                                startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                                endDate: dayjs(new Date()).format('MM-DD-YYYY')
                            }
                        ];
                        state[columnFilterName] = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    }
                }
            }
        },
        updateAppliedList(state, action) {
            const { updateType, columnName, listType, columnFilterName = 'columnFilter' } = action.payload;
            let tempColumnFilterOne = { ...state[columnFilterName] };
            if (updateType === 'UPDATE') {
                // new checked list moves to the applied list
                // need to avoid dublicate values
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [
                        {
                            startDate: tempColumnFilterOne[columnName].checkedList[0].startDate,
                            endDate: tempColumnFilterOne[columnName].checkedList[0].endDate
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList.forEach((item) => {
                        //  if (!tempExistingAppliedListObj[item.id])
                        tempColumnFilterOne[columnName].appliedList.push(item);
                    });
                }

                state[columnFilterName] = { ...tempColumnFilterOne };
                // tempExistingAppliedListObj = {};
            } else if (updateType === 'CLEAR') {
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [
                        {
                            startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                            endDate: dayjs(new Date()).format('MM-DD-YYYY')
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [];
                }
            }
            tempColumnFilterOne = {};
        },
        // set Customer Filter for Product Management
        setAppliedCustomerFIlter(state, action) {
            state.appliedCustomerFIlter = action.payload;
        },
        // Column filter

        // Delievery Address list
        startDelieveryAddressList(state, action) {
            state.isLoadingForAddress = true;
        },
        setDelieveryAddressList(state, action) {
            state.deliveryAddressList = action.payload;
        },
        // Products Management
        startProductsColumnList(state, action) {
            state.isLoading = true;
        },
        setProductsColumnList(state, action) {
            state.productsDetailsList = action.payload;
        },
        // Products Management

        startLookUpMaster(state, action) {
            state.isLoading = true;
        },
        startLookUpDetailsByLookupId(state, action) {
            state.isLoading = true;
        },
        startLookUpDetailsByLookupId1(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails1Data(state, action) {
            state.lookupDetailForId1 = action.payload;
        },
        startLookUpDetailsByLookupId2(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails2Data(state, action) {
            state.lookupDetailForId2 = action.payload;
        },
        startLookUpDetailsByLookupId3(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails3Data(state, action) {
            state.lookupDetailForId3 = action.payload;
        },
        startLookUpDetailsByLookupId4(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails4Data(state, action) {
            state.lookupDetailForId4 = action.payload;
        },
        startLookUpDetailsByLookupId5(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails5Data(state, action) {
            state.lookupDetailForId5 = action.payload;
        },
        setLookUpDetailsData(state, action) {
            state.lookupDetailsData = action.payload;
        },
        setLookUpMasterData(state, action) {
            state.lookupMasterData = action.payload;
        },
        startAddEditNewLookupsDetails(state, action) {
            state.isLoading = true;
        },

        startDelete(state, action) {
            state.isLoading = true;
        },
        startDeleteLookupDetails(state, action) {
            state.isLoading = true;
        },
        startUpdateingStatusById(state, action) {
            state.isLoading = true;
        },
        startUpdateData(state, action) {
            state.isLoading = true;
        },
        completedFetch(state, action) {
            state.isLoading = false;
            state.isLoadingForAddress = false;
        },
        // Roles Permission
        addUpdatePermissions(state, action) {
            state.isLoading = true;
        }
    }
});
///
export const {
    setRowsPerPage,
    setPage,
    // Delivery Address List
    startDelieveryAddressList,
    setDelieveryAddressList,
    //
    startLookUpDetails,
    setLookUpDetailsData,
    startAddEditNewLookupsDetails,
    startDelete,
    startLookUpMaster,
    setLookUpMasterData,
    startLookUpDetailsByLookupId,
    startDeleteLookupDetails,
    startLookUpDetailsByLookupId1,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5,
    setLookUpDetails1Data,
    setLookUpDetails2Data,
    setLookUpDetails3Data,
    setLookUpDetails4Data,
    setLookUpDetails5Data,
    completedFetch,
    startUpdateingStatusById,
    startUpdateData,
    // Roles Permission
    // set Applied Customer FIlter for Products List Page
    setAppliedCustomerFIlter,

    setPaginationByPageNames,
    setPageLeft,
    setColumnCheckedList,
    clearUnsavedCheckList,
    updateAppliedList,
    startProductsColumnList,
    setProductsColumnList,
    //
    addUpdatePermissions
} = slice.actions;

export default slice.reducer;

// Selectors
const mainLookupDetailsState = (state) => state.lookupDetails;
//
export const isLoadingSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.isLoading);
//
//   Loading For Address  isLoadingForAddress
export const isLoadingForAddressSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.isLoadingForAddress);
//
export const lookUpDetailsSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.lookupDetailsData);
//
export const lookUpDetailByIdSelector = createSelector([mainLookupDetailsState], (lookUpState) => ({
    lookupDetailForId1: lookUpState.lookupDetailForId1,
    lookupDetailForId2: lookUpState.lookupDetailForId2,
    lookupDetailForId3: lookUpState.lookupDetailForId3,
    lookupDetailForId4: lookUpState.lookupDetailForId4,
    lookupDetailForId5: lookUpState.lookupDetailForId5
}));
//
export const lookUpMasterSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.lookupMasterData);
//
// Page Selector
export const pageSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.rowsPerPage);
//
export const columnFilterSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.columnFilter);

export const paginationFilterSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.paginations);

export const pageLeftSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.pageLeft);

export const productsListSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.productsDetailsList);
// Applied-Customer-FIlter only for Product Management
export const appliedCustomerFIlterSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.appliedCustomerFIlter);
//
export const deliveryAddressListSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.deliveryAddressList);
//
