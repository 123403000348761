import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';

const securedRoute = {
    addNewGaiapod: 'gaiapod-device/add',
    baseList: 'gaiapod-device',
    updateGaiapod: 'gaiapod-device/update/##ID##',
    getGaiapodDetailsById: 'gaiapod-device/##ID##',
    getGaiapodHistory: '/gaiapod-device/history/##ID##'
};

// Gaiapod List
export const getGaiapodList = async () => {
    const res = await securedAxiosAPI.get(securedRoute?.baseList);
    return res.data;
};

// Add New Gaiapod
export const addNewGaiapodToList = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addNewGaiapod, data);
    return res.data;
};
// Update Gaiapod By Id
export const updateGaiapodById = async (data) => {
    const { id } = data;
    const res = await securedAxiosAPI.post(securedRoute?.updateGaiapod.replaceAll('##ID##', id), data);
    return res.data;
};
// Get Gaiapod By Id
export const getGaiapodById = async (id) => {
    const res = await securedAxiosAPI.get(securedRoute?.getGaiapodDetailsById.replaceAll('##ID##', id));
    return res.data;
};
//   Gaiapod History By Id
export const getGaiapodHistoryById = async (id) => {
    const res = await securedAxiosAPI.get(securedRoute?.getGaiapodHistory.replaceAll('##ID##', id));
    return res.data;
};
