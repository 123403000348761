// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    //
    trackingList: [],
    lostSamples: [],
    lostGaiapodList: [],
    isLoading: false,
    message: ''
};

const slice = createSlice({
    name: 'pickupTracking',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startGettingTrackList(state, action) {
            state.isLoading = true;
        },
        setTrackingList(state, action) {
            state.trackingList = action.payload;
        },
        startAddingTrackingList(state, action) {
            state.isLoading = true;
        },
        //
        startLostSamplesList(state, action) {
            state.isLoading = true;
        },
        setLostSamplesList(state, action) {
            state.lostSamples = action.payload;
        },

        startLostGaiapodList(state, action) {
            state.isLoading = true;
        },
        setLostGaiapodList(state, action) {
            state.lostGaiapodList = action.payload;
        },
        //
        completedAction(state, action) {
            state.isLoading = false;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    completedAction,
    startGettingTrackList,
    setTrackingList,
    startAddingTrackingList,
    startLostSamplesList,
    setLostSamplesList,
    startLostGaiapodList,
    setLostGaiapodList
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors

const trackingState = (state) => state.trackingPickup;
/// Data Loading Selector
export const isLoadingSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.isLoading);
// Page Selector
export const pageSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.rowsPerPage);
// lsit
export const trackingListSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.trackingList);
// lostSamples
export const lostSamplesSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.lostSamples);
// lostGaiapodList
export const lostGaiapodListsSelector = createSelector([trackingState], (trackingDataState) => trackingDataState.lostGaiapodList);
